import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo } from 'react';
import Loader from '../components/Loader/Loader';
import { jwtService } from '../services/JWTService';
import { authStoreContext } from '../stores/AuthStore';
import { userStoreContext } from '../stores/UserStore';
import { checkIsClient } from '../utils/checkIsClient';

export interface PrivateRouteProps {}

const withPrivateRoute = (WrappedComponent, roles: string[]) => {
	const PrivateRoute = (props) => {
		const { authenticated, auth, authentication, logout } = useContext(authStoreContext);
		const { user } = useContext(userStoreContext);

		const isClient = useMemo(() => checkIsClient(), []);

		useEffect(() => {
			if (!isClient) return;

			if (!jwtService.getToken()) {
				logout();
				navigate('/401', { replace: true });
			}

			if (user && !roles.includes(user.role)) {
				navigate('/403', { replace: true });
			}

			if (authenticated) return;

			auth().catch(() => navigate('/'));

			if (authentication) return;

			if (authenticated) {
				navigate('/401', { replace: true });
			}
		}, [authentication, isClient, user]);

		if (authentication) return <Loader />;

		return <WrappedComponent {...props} />;
	};

	return observer(PrivateRoute);
};

export default withPrivateRoute;
