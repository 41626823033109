import React from 'react';
import { Helmet } from 'react-helmet';
import withPrivateRoute from '../hocs/withPrivateRoute';
import { Roles } from '../utils/constants';
import StartSteps from '../modules/Landing/StartSteps/StartSteps';
import UseService from '../modules/Landing/UseService/UseService';
import WelcomeScreen from '../modules/Landing/WelcomeScreen/WelcomeScreen';
import About from '../modules/Landing/About/About';

const Home: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Home</title>
			</Helmet>

			<div className="pl-4">
				<header className="z-50 w-full items-center text-white fixed py-3 bg-main-blue">
					<nav>
						<ul className="flex gap-12 m-0 text-base">
							<li>
								<a href="#about">About</a>
							</li>
							<li>
								<a href="#steps">Steps</a>
							</li>
						</ul>
					</nav>
				</header>
				<div className="bg-landing-top bg-no-repeat bg-cover bg-top">
					<div className="container relative">
						<WelcomeScreen pathForRedirect={'/user/profile'} />
						<UseService />
						<StartSteps />
					</div>
				</div>
				<div className="bg-landing-bottom bg-no-repeat bg-cover">
					<div className="container relative">
						<About />
					</div>
				</div>
			</div>
		</>
	);
};

export default withPrivateRoute(Home, [Roles.admin, Roles.user]);
